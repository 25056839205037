import React from 'react'
import dynamic from 'next/dynamic'
import { useMobileDetect } from '@/hooks/useMobileDetect'
import styles from './AccessibilityModal.module.scss'

const Text = dynamic(import('@csc/dls/Text'))
const CloseIconBold = dynamic(import('@csc/dls/Icons/CloseIconBold'))

const Modal = dynamic(import('@csc/dls/Modal'))

const AccessibilityModal: React.FC<{
  open: boolean
  onClose: () => void
}> = ({ open, onClose }) => {
  const isMobile = useMobileDetect()

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeable
      closeButton
      closeIcon={CloseIconBold}
      closeIconSize="sm"
      rounded
      type={isMobile ? 'slide' : undefined}
    >
      {open && (
        <div className={styles.mainContent}>
          <Text bold size="xl">Our Commitment to Accessibility</Text>

          <Text className="mt-4">

            {
            // eslint-disable-next-line max-len
            'We are committed to ensuring that our website is open and accessible to the broadest audience possible,'
            }
            including those with visual, hearing, and other disabilities.
          </Text>

          <Text className="mt-4">
            {
            // eslint-disable-next-line max-len
            'We strive to continually improve the user experience for everyone by consulting relevant accessibility'
            }
            standards.
          </Text>

          <Text className="mt-4">
            {
            // eslint-disable-next-line max-len
            'If you have questions, comments, or encounter any difficulty relating to the accessibility of our website,'
            }
            please contact the One Kings Lane Accessibility Team at
            {' '}
            <a
              href="mailto:accessibility@onekingslane.com"
            >
              accessibility@onekingslane.com.
            </a>
          </Text>
        </div>
      )}
    </Modal>
  )
}

export default AccessibilityModal
